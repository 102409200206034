<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="onFormSubmit">
                    <b-card-title>
                      What would you like in the main body of your website?
                      <span class="font-weight-normal">(Optional)</span>
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'copy']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          You can add your candidate statement or any other
                          content about who you are and your campaign in this
                          section.
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
                    <b-form-group>
                      <vue-editor
                        useCustomImageHandler
                        @image-added="handleImageAdded"
                        v-model="candidateModel.openContent"
                        :editor-toolbar="customToolbar"
                        @text-change="onContentChange"
                        placeholder="If you are pasting content into this box please use the shortcut CTRL (or CMD) + SHIFT + V otherwise you may end up with unintentional formatting."
                      />
                    </b-form-group>
                    <b-button
                      v-show="false"
                      ref="submit"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-skip="onSkip"
                @on-back="onBack"
                @on-preview="onPreview"
                :hideSkipButton="false"
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="openContent"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
import Repository from "@/repositories/RepositoryFactory";

const CandidateRepository = Repository.get("CandidateRepository");
export default {
  components: { VueEditor },
  name: "AboutOpenContext",
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      hideSkipButton: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "+1" }],
        ["blockquote", "link", "image"]
      ],
      loading: false,
      errorMessage: null,
      enableLocalPaste: true
    };
  },
  methods: {
    onContentChange() {
      if (
        this.candidateModel.openContent.localeCompare(
          this.candidateTemp.openContent
        ) !== 0
      )
        this.$refs.observer.flags.dirty = true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onPreview() {},
    onBack() {
      this.$router.push("intro-text");
    },
    onSkip() {
      this.$router.push("feature-text");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        openText: this.candidateModel.openContent
      };

      this.isSubmitting = true;
      this.saving = true;

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.candidateService
            .SaveOpenContentAsync(payload)
            .then(res => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  this.$router.push("feature-text");
                }
              }, 500);
            })
            .catch(e => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
    handleImageAdded(file, Editor, cursorLocation) {
      const data = {
        userId: this.$route.params.id,
        image: file
      };

      CandidateRepository.UploadEditorImage(data)
        .then(result => {
          let url = result.data;
          Editor.insertEmbed(cursorLocation, "image", url);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
